export const DescriptionText = [
  {
    name: '조커 뽑기',
    description:
      '같은 숫자를 패에서 제거하세요. 패를 모두 제거하면 승리합니다.',
    path_link: 'https://cdn.ivis.dev/capstone/images/draw_joker.png',
    select: true,
    room: 0,
  },
  {
    name: '동물 포커',
    description: '높은 점수를 획득하세요. 5장의 카드를 가지고 있습니다.',
    path_link: 'https://cdn.ivis.dev/capstone/images/animal_poker.png',
    select: false,
    room: 4,
  },
  {
    name: '감정 경마',
    description: '감정을 사용해 경마게임의 우승자가 되세요.',
    path_link: 'https://cdn.ivis.dev/capstone/images/racing.png',
    select: false,
    room: 5,
  },
];
